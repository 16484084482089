import DatePicker from "react-datepicker";
import useReadWithGet from "../../hooks/read/useReadWithGetMethod";
import Moment from "react-moment";
import Spinner from "../../components/loader/Spinner";
import Pagination from "../../components/pagination";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
const PunchLogs = () => {
  
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialData = {
    month_year: new Date(),
    pageNo: 0,
    limit: 10
  };

  const { list, paramsObject, setGetListParams } = useReadWithGet({
    url: 'user_check_in_list',
    initialData: initialData,
    onError: (err) => {
      console.log(err);
    }
  })

  useEffect(() => {
    setGetListParams((prev) => {
      return {
        ...prev,
        month_year: searchParams.get('month_year') || new Date(),
        page_number: searchParams.get('pageNo') || 0,
      }
    })
  }, [location]);

  const handleChangeMonth = (value) => {
    let data = {
      ...paramsObject,
      month_year: value
    }
    setGetListParams(data)
    setSearchParams(data)
  }

  const handlePageClick = (val) => {
    let data = {
      ...paramsObject,
      page_number: val.selected
    }
    setGetListParams(data)
    //  setSearchParams(data)
  }
  return (
    <div className=" px-5">
      {(list.isLoading || list.isFetching) ? <Spinner /> 
      : <>

        <div className="flex items-center">
          <div className=" lg:basis-[25%] mb-5">
            <label className=" roboto p-2 label">Select Year</label>
            <DatePicker
              name="month_and_year"
              autoComplete="false"
              className="input"
              dateFormat="MM/yyyy"
              maxDate={new Date()}
              selected={new Date(paramsObject?.month_year)}
              showMonthYearPicker
              onChange={handleChangeMonth}
            />
          </div>
        </div>
        <table class="table-auto w-full">
          <thead>
            <tr class=" bg-[color:var(--color1)] text-center">
              <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4 border-l border-transparent">
                Sr.No
              </th>
              <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4">
                Recorded on
              </th>
              <th class=" text-base font-semibold text-white py-2 lg:py-2 px-3 lg:px-4 border-l border-transparent">
                Approval Status
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.data?.data?.length
              ? <>
                {list.data?.data?.map((data, idx) => {
                  
                  const { punch_time, approval_status } = data
                  const new_punch_time = punch_time * 1000;
                  const srNo = idx + 1 + paramsObject.page_number * paramsObject.limit;
                  return <tr key={idx}>
                    <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{srNo}</td>
                    <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                      <span className=" text-xs bg-gray-200 px-2 py-1 rounded-md">
                        <Moment format="LLL">
                          {new_punch_time}
                        </Moment>
                      </span>
                    </td>
                    <td class="text-center text-dark font-medium text-sm lg:text-base py-2 lg:py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                      <span>{approval_status}</span>
                    </td>
                  </tr>
                })}
              </>
              : <>
                <tr>

                  <td colSpan={3}>
                    {/* <p className=" text-center py-2 font-semibold">No Record Found for {<Moment date={paramsObject?.month_year} format="MM-YYYY" />}</p> */}
                  </td>
                </tr>
              </>}
          </tbody>
        </table>
        <div className='px-5 mt-5'>
          <Pagination
            currentPage={+paramsObject.page_number}
            lengthofItems={+list?.data?.total_count}
            limit={+paramsObject.limit}
            onPageChange={handlePageClick} 
          />
        </div>
      </>}
    </div>
  );
};

export { PunchLogs };
